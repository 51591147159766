@use 'sass:math';
@import '../../../../../core/styles/ui/breakpoints.scss';
@import '../../../../../core/styles/ui/colors.scss';
@import '../../../../../core/styles/ui/spacing.scss';
@import '../../../../../core/styles/ui/typography.scss';

@import '../../../../../core/styles/ui/components/app-footer.scss';

// spacing
$baseline: 16px;

// borders
$border-radius-l: 12px;
$border-radius-m: $baseline * 0.5;
$border-radius-s: $baseline * 0.25;

$border-width-s: 1px;

// colors
$color-primary: #f4616b;
$color-white: #fff;
$color-black: #000;
$color-primary-bg: #f6f6f6;
$color-secondary-bg: #e1e5ee;
$color-header-primary-bg: #475a79;
$color-banner-blue: #4e74b7;

$color-success: #55a266;
$color-warning: #f4616b;
$color-icon: #475a79;
/// text on background
$text-color-secondary-bg: #676e78;

/// text on primary color
$text-color-primary-bg: #242d38;

@import '~@core/styles/app.scss';

@import 'pages/book-single-phone-consultation.scss';
@import 'pages/articles.scss';

body {
  font-family: var(--font-family-primary);
  color: $text-color-primary-bg;
}

.slide-container {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.pager-dot {
  width: 14px;
  height: 14px;
  border-radius: 50%;
  background-color: #e7eaf2;
  margin: 5px;
}

.pager-dot:hover {
  cursor: pointer;
}

.pager-dots {
  display: flex;
  padding: 5px;
  justify-content: center;
}

.pager-dot.active {
  background-color: $color-primary;
}

.btn-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 50px;
  flex: 1;
}

.slide-content {
  display: flex !important;
  flex: 1;
  flex-direction: column;
  height: 70vh;
}

.slick-list,
.slick-slider,
.slick-track {
  position: relative;
  display: block;
}

.slick-loading .slick-slide,
.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slider {
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  overflow: hidden;
  margin: 0;
  padding: 0;
}

.slick-list:focus {
  outline: 0;
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-list,
.slick-slider .slick-track {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  top: 0;
  left: 0;
}

.slick-track:after,
.slick-track:before {
  display: table;
  content: '';
}

.slick-track:after {
  clear: both;
}

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px;
}

[dir='rtl'] .slick-slide {
  float: right;
}

.slick-slide img {
  display: block;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

.custom-tooltip-initialize {
  width: 250px;
  margin-left: 10px !important;
  white-space: break-spaces;
}

.book-tooltip-text {
  overflow: hidden;
}
