@use 'sass:math';
@import '../../styles/ui/breakpoints.scss';
@import '../../styles/ui/colors.scss';
@import '../../styles/ui/spacing.scss';
@import '../../styles/ui/typography.scss';

@import '../../styles/ui/components/app-footer.scss';

// spacing
$baseline: 16px;

// borders
$border-radius-l: 12px;
$border-radius-m: $baseline * 0.5;
$border-radius-s: $baseline * 0.25;

$border-width-s: 1px;

// colors
$color-primary: #f4616b;
$color-white: #fff;
$color-black: #000;
$color-primary-bg: #f6f6f6;
$color-secondary-bg: #e1e5ee;
$color-header-primary-bg: #475a79;
$color-banner-blue: #4e74b7;

$color-success: #55a266;
$color-warning: #f4616b;
$color-icon: #475a79;
/// text on background
$text-color-secondary-bg: #676e78;

/// text on primary color
$text-color-primary-bg: #242d38;

.button {
  font-weight: 700;
  border: 0;
  min-width: 240px;
  cursor: pointer;
  font-size: var(--font-size-l);
  line-height: 1.333333333;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;

  &.large {
    padding: $baseline $baseline * 1.25;
  }

  &.medium {
    padding: $baseline * 0.75 $baseline * 1.25;
  }

  &--disabled {
    cursor: not-allowed;
    background-color: #f0f1f2 !important;
    border: none !important;
    opacity: 0.4;
  }

  @media screen and (max-width: 340px) {
    min-width: auto;
  }
}

.primary {
  background-color: $color-primary;
  color: $color-white;
  box-shadow: 2px 4px 8px rgba(71, 90, 121, 0.25);
}

.secondary {
  background-color: $color-white;
  color: $text-color-primary-bg;
  border: $border-width-s solid var(--color-metal-standard);
}

// Button styles

.rounded {
  border-radius: $border-radius-m;
}

.fullRounded {
  border-radius: 100px;
}

.square {
  border-radius: 0;
  box-shadow: none;
}

.endIcon svg {
  margin-left: $baseline * 0.25;
  vertical-align: bottom;
}

.startIcon svg {
  margin-right: $baseline * 0.25;
  vertical-align: bottom;
}
