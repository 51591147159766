@use 'sass:math';
@import '../../../../styles/ui/breakpoints.scss';
@import '../../../../styles/ui/colors.scss';
@import '../../../../styles/ui/spacing.scss';
@import '../../../../styles/ui/typography.scss';

@import '../../../../styles/ui/components/app-footer.scss';

// spacing
$baseline: 16px;

// borders
$border-radius-l: 12px;
$border-radius-m: $baseline * 0.5;
$border-radius-s: $baseline * 0.25;

$border-width-s: 1px;

// colors
$color-primary: #f4616b;
$color-white: #fff;
$color-black: #000;
$color-primary-bg: #f6f6f6;
$color-secondary-bg: #e1e5ee;
$color-header-primary-bg: #475a79;
$color-banner-blue: #4e74b7;

$color-success: #55a266;
$color-warning: #f4616b;
$color-icon: #475a79;
/// text on background
$text-color-secondary-bg: #676e78;

/// text on primary color
$text-color-primary-bg: #242d38;

.genericConsultation {
  background-color: $color-white;
  height: 270px;
  min-height: 270px;
  margin: 10px 15px 10px 5px;
  padding: $baseline * 1.5;
  flex-basis: 50%;

  @media screen and (min-width: $tablet) {
    width: calc(50% - 20px);
    display: inline-block;
  }

  .wrapper {
    display: grid;
    grid-template-rows: minmax(45px, auto) auto 1fr 45px;
    height: 100%;
  }

  header {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: $baseline;

    .serviceIcon {
      background: $color-icon;
      flex-shrink: 0;
      width: 43px;
      height: 43px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    h3 {
      font-size: var(--font-size-xl);
      font-weight: 700;
      margin-left: $baseline;
      color: $text-color-primary-bg;

      @media screen and (min-width: ($laptop - 1)) {
        font-size: var(--font-size-m);
      }
    }
  }

  .content {
    h3 {
      display: none;

      @media screen and (min-width: $laptop) {
        display: block;
      }
    }

    p {
      font-size: var(--font-size-s);
      line-height: 1.28;
      color: $text-color-secondary-bg;
    }
  }

  .icons {
    display: flex;
    background-color: #f0f2f7;
    margin-top: auto;
    padding: $baseline;
    justify-content: space-around;

    .iconBlock {
      text-align: center;

      p {
        font-size: var(--font-size-xs);
        color: $text-color-secondary-bg;
      }
    }
  }

  .bookLink {
    line-height: 55px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    button {
      text-decoration: none;
      line-height: 55px;
    }

    .translation {
      color: $text-color-secondary-bg;
      font-size: var(--font-size-xs);
      margin-top: 3px;
    }
  }

  &.specialtiesDisabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}

// GP service special
.genericConsultation.gpConsultation {
  @media screen and (max-width: ($tablet - 1)) {
    background-image: none !important;
  }

  @media screen and (min-width: $tablet) {
    background-repeat: no-repeat;
    background-size: cover;
    background-color: rgba(0, 0, 0, 0.4);
    background-blend-mode: darken;
    background-position-y: 59%;
    padding: 0;

    // make the card big layout
    width: calc(100% - 20px);
    display: block;
    margin: 0 140px 10px 5px;

    .wrapper {
      grid-template-columns: 1fr 100px;
      grid-template-rows: 80px 1fr 55px;
      grid-template-areas:
        'header icons'
        'content icons'
        'button button';
    }

    header {
      grid-area: header;
      padding: $baseline;
      margin-bottom: 0;

      h3 {
        display: none;
      }
    }

    .content {
      grid-area: content;
      padding: 0 $baseline;

      h3 {
        display: block;
        color: $color-white;
        font-size: var(--font-size-xxl);
        font-weight: 700;
      }

      p {
        color: $color-white;
        font-size: var(--font-size-m);
      }
    }

    .icons {
      background: none;
      flex-direction: column;
      margin-top: 110px;

      .iconBlock {
        margin-bottom: $baseline * 2;

        svg path {
          stroke: $color-white;
        }

        p {
          color: $color-white;
        }
      }
    }

    .bookLink {
      grid-area: button;
      background-color: $color-white;
      padding: 0 $baseline;

      button {
        font-size: var(--font-size-l);
      }

      .translation {
        margin-top: 0;
        font-size: var(--font-size-s);
      }
    }
  }
}
