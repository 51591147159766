@use 'sass:math';
@import '../../styles/ui/breakpoints.scss';
@import '../../styles/ui/colors.scss';
@import '../../styles/ui/spacing.scss';
@import '../../styles/ui/typography.scss';

@import '../../styles/ui/components/app-footer.scss';

// spacing
$baseline: 16px;

// borders
$border-radius-l: 12px;
$border-radius-m: $baseline * 0.5;
$border-radius-s: $baseline * 0.25;

$border-width-s: 1px;

// colors
$color-primary: #f4616b;
$color-white: #fff;
$color-black: #000;
$color-primary-bg: #f6f6f6;
$color-secondary-bg: #e1e5ee;
$color-header-primary-bg: #475a79;
$color-banner-blue: #4e74b7;

$color-success: #55a266;
$color-warning: #f4616b;
$color-icon: #475a79;
/// text on background
$text-color-secondary-bg: #676e78;

/// text on primary color
$text-color-primary-bg: #242d38;

.infoIcon {
  margin: 0 0 0 $baseline * 0.5;

  path:nth-child(1) {
    stroke: $text-color-primary-bg;
  }

  path:nth-child(2) {
    fill: $text-color-primary-bg;
  }

  &.clickable {
    cursor: pointer;
  }

  &.fillBg {
    path:nth-child(1) {
      fill: $text-color-primary-bg;
    }

    path:nth-child(2) {
      fill: $color-white;
    }
  }

  &.regularWhite {
    path:nth-child(1) {
      fill: var(--color-white);
      stroke: none;
    }

    path:nth-child(2) {
      fill: var(--color-azure-standard);
    }
  }

  &.positive {
    path:nth-child(1) {
      stroke: #37a489;
    }

    path:nth-child(2) {
      fill: #37a489;
    }

    &.fillBg {
      path:nth-child(1) {
        fill: #37a489;
      }

      path:nth-child(2) {
        fill: $color-white;
      }
    }
  }

  &.alert {
    path:nth-child(1) {
      stroke: $color-warning;
    }

    path:nth-child(2) {
      fill: $color-warning;
    }

    &.fillBg {
      path:nth-child(1) {
        fill: $color-warning;
      }

      path:nth-child(2) {
        fill: $color-white;
      }
    }
  }
}
