@use 'sass:math';
@import '../../styles/ui/breakpoints.scss';
@import '../../styles/ui/colors.scss';
@import '../../styles/ui/spacing.scss';
@import '../../styles/ui/typography.scss';

@import '../../styles/ui/components/app-footer.scss';

// spacing
$baseline: 16px;

// borders
$border-radius-l: 12px;
$border-radius-m: $baseline * 0.5;
$border-radius-s: $baseline * 0.25;

$border-width-s: 1px;

// colors
$color-primary: #f4616b;
$color-white: #fff;
$color-black: #000;
$color-primary-bg: #f6f6f6;
$color-secondary-bg: #e1e5ee;
$color-header-primary-bg: #475a79;
$color-banner-blue: #4e74b7;

$color-success: #55a266;
$color-warning: #f4616b;
$color-icon: #475a79;
/// text on background
$text-color-secondary-bg: #676e78;

/// text on primary color
$text-color-primary-bg: #242d38;

.landing {
  background-color: $color-white;
  display: grid;
  min-height: 640px;

  @media screen and (min-width: 1024px) {
    max-width: 100%;
  }
}

.content {
  width: 100%;
  height: 70vh;
  overflow: hidden;

  @media screen and (min-width: $laptop) {
    border-radius: 0 !important;
  }

  .container {
    flex: 1;
  }

  .logoContainer {
    justify-content: center;

    img {
      height: 70px;
    }
  }

  .mainHeader,
  .mission {
    max-width: 222px;
  }
}

.btnContainer {
  @media screen and (min-width: $tablet) {
    width: 280px;
  }
}

.anonymousLanding {
  color: var(--color-white);
  display: flex;
  flex-direction: column;

  @media screen and (min-width: $tablet) {
    background-color: var(--color-grey-10);
    color: var(--color-metal-standard);
  }

  & + footer > :first-child {
    display: block;
  }

  .clientLogo {
    height: 64px;
    margin-bottom: var(--spacing-4);

    @media screen and (min-width: $tablet) {
      margin: 0;
    }
  }

  .header {
    text-align: center;

    @media screen and (min-width: $tablet) {
      flex-direction: row;
      justify-content: space-between;
      background-color: var(--color-metal-standard);
      color: var(--color-white);
      text-align: left;
      box-shadow: 0px 4px 16px -4px #242d383d;
    }
  }

  .headerContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: var(--spacing-12);

    @media screen and (min-width: $tablet) {
      flex-direction: row;
      justify-content: space-between;
      margin: 0 auto;
      padding: var(--spacing-4) 100px;
    }
  }

  .card {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    background: var(--color-white);
    color: var(--color-metal-standard);
    border-radius: 0.5rem;

    @media screen and (min-width: $tablet) {
      flex-direction: row;
      align-items: center;
    }

    .cardHeader {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      gap: 20px;

      @media screen and (min-width: $tablet) {
        flex: 1;
      }
    }

    .cardActionContainer {
      margin: 0 auto;

      @media screen and (min-width: $tablet) {
        margin: 0;
        padding-right: var(--spacing-4);
      }
    }
  }
}

.anonymousContent {
  display: flex;
  flex-direction: column;
  padding: 0 var(--spacing-6);
  margin: 0 auto;

  @media screen and (min-width: $tablet) {
    padding: 0 100px;
    flex-direction: column-reverse;
  }
}

.anonymousActionsContainer {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: var(--spacing-4);
  padding: var(--spacing-12) 0;

  @media screen and (min-width: $tablet) {
    margin: 0;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: flex-start;
    gap: var(--spacing-5);
    padding-top: var(--spacing-8);
    padding-bottom: var(--spacing-14);

    .loginActionText {
      display: none;
    }
  }
}

.mobile {
  @media screen and (min-width: $tablet) {
    display: none;
  }
}

.tablet {
  display: none;

  @media screen and (min-width: $tablet) {
    display: initial;
  }
}
